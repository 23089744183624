<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical"
    @open="handleOpen"
    @close="handleClose"
    :collapse="$store.state.isCollapse"
    :collapse-transition="true"
    background-color="#545c64"
    text-color="#fff"
  >
    <div class="menu_trademark">
      <img src="@/assets/baka.jpg" alt="" width="50px" height="50px" />
      <span v-show="!$store.state.isCollapse">FLandreの管理台</span>
    </div>

    <el-submenu index="1">
      <template slot="title">
        <i class="el-icon-location"></i>
        <span slot="title">系统管理</span>
      </template>
      <el-menu-item index="1-2" @click="adminList">
        <i class="el-icon-menu"></i>
        <span slot="title">管理员列表</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="2">
      <template slot="title">
        <i class="el-icon-s-shop"></i>
        <span slot="title">商品管理</span>
      </template>
      <el-menu-item index="2-1" @click="goodsList">
        <i class="el-icon-menu"></i>
        <span slot="title">商品列表</span>
      </el-menu-item>
      <el-menu-item index="2-2">
        <i class="el-icon-menu"></i>
        <span slot="title">商品分类列表</span>
      </el-menu-item>
      <el-menu-item index="2-3">
        <i class="el-icon-menu"></i>
        <span slot="title">商品参数属性列表</span>
      </el-menu-item>
      <el-menu-item index="2-4">
        <i class="el-icon-menu"></i>
        <span slot="title">轮播图列表</span>
      </el-menu-item>
    </el-submenu>

    <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-unlock"></i>
        <span slot="title">权限管理</span>
      </template>
      <el-menu-item index="3-1">
        <i class="el-icon-menu"></i>
        <span slot="title">角色列表</span>
      </el-menu-item>
      <el-menu-item index="3-2">
        <i class="el-icon-menu"></i>
        <span slot="title">权限列表</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-document"></i>
        <span slot="title">订单管理</span>
      </template>
      <el-menu-item index="4-1">
        <i class="el-icon-menu"></i>
        <span slot="title">订单列表</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title">
        <i class="el-icon-setting"></i>
        <span slot="title">用户</span>
      </template>
      <el-menu-item index="5-1">
        <i class="el-icon-menu"></i>
        <span slot="title">用户密码修改</span>
      </el-menu-item>
      <el-menu-item index="5-2">
        <i class="el-icon-menu"></i>
        <span slot="title">选项2</span>
      </el-menu-item>
    </el-submenu>
    <el-menu-item index="5" @click="logout">
      <i class="el-icon-s-promotion"></i>
      <span slot="title">退出</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
// import { GoodsList } from '../../api/goods';
export default {
  name: "Menu",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
     handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      this.$router.replace('/logout');
    },
    goodsList() {
      this.$router.push('goodsList');
    },
    adminList() {
      this.$router.push('manager');
    },
  },
};
</script>

<style>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  height: 100%;
}
.menu_trademark {
  color: bisque;
  font-variant: small-caps;
  font-family: Cursive;
}
.menu_trademark span {
  position: absolute;
  top: 25px;
}
.menu_trademark img {
  margin: 7px 5px 7px 7px;
  border-radius: 15px;
}
</style>