<template>
  <div class="home">
    <el-container style="height: 100ch; border: 1px solid #eee">
      <my-menu></my-menu>
      <el-container>
        <el-header style="text-align: left; font-size: 23px">
          <i
            v-show="!homeIsCollapse"
            @click="isNavigationBar"
            class="el-icon-s-fold"
            style="margin-right: 15px"
          ></i>
          <i
            v-show="homeIsCollapse"
            @click="isNavigationBar"
            class="el-icon-s-unfold"
            style="margin-right: 15px"
          ></i>
        </el-header>
        <el-main>
          <!-- <manager></manager> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MyMenu from "./homemenu/menu.vue";

export default {
  name: "Home",
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      homeIsCollapse: false,
    };
  },
  components: {
    MyMenu,
  },
  mounted() {},
  methods: {
    isNavigationBar() {
      this.homeIsCollapse = !this.homeIsCollapse;
      this.$store.commit("modifyCollapse", this.homeIsCollapse);
    },
  },
};
</script>

<style>
/* .el-main {
  background-color: aqua;
} */

.el-header {
  background-color: #f0f2f5;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
